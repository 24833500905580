// Single-product page template:

import React from "react"
import { Layout, ProductPage } from "../components"

const imageList = [
	{
		link:`/images/featured_aviator.jpg`,
		alt:`Blue aviator flight suite with a white background.`,
	},
	{
		link:`/images/aviator-models.jpg`,
		alt:`One male and one female pilot standing in front of a grey background wearing the blue aviator flight suit.`,
	},
]


export default function ProductPageAviator() {
	return (
		<Layout
			title="Aviator"
		>
			<ProductPage images={imageList}>
				<h2>Aviator Flight Suit</h2>
				<p>Our bestselling flight suit is available in poly / cotton and fire resistant Nomex and is like the military style suit. We have manufactured these for the Air Medical rescue personnel, law enforcement, United States Coast Guard, and others.</p>

				<h3>Features</h3>
				<ul>
					<li>2 angle chest pockets</li>
					<li>2” x 4” velcro for name tag</li>
					<li>2 thigh pockets, outside of leg</li>
					<li>2 lower leg pockets</li>
					<li>Zipper sleeve pocket</li>
					<li>Knife pocket</li>
					<li>Boot zipper entry</li>
				</ul>
			</ProductPage>

		</Layout>
	)
}
